
import { useState, useEffect } from 'react';

function debounce(fn, ms) {
      let timer
      return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
          timer = null
          fn.apply(this, arguments)
        }, ms)
      };
    }

export const useWindowSize = (initialState = "100%", { ttl = 100 } = {}) => {
  // initialState is used before the component mounts client-side
  const [width, setWidth] = useState(initialState)
  const [height, setHeight] = useState(initialState)

  useEffect(() => {
    const calculateWidth = debounce(() => {
      setWidth(window.innerWidth)
    }, ttl)

    calculateWidth()
    window.addEventListener("resize", calculateWidth)
    
    const calculateHeight = debounce(() => {
      setHeight(window.innerHeight)
    }, ttl)

    calculateHeight()
    window.addEventListener("resize", calculateHeight)

    return () => {
      // deregister event listener on component dismount
      window.removeEventListener("resize", calculateWidth)
      window.removeEventListener("resize", calculateHeight)
    }
  }, [ttl])

  return [width, height]
  
}

// let defaultHeight
// let defaultWidth

// if (typeof window !== `undefined`) {
//   defaultHeight = window.innerHeight
//   defaultWidth = window.innerWidth
// }

// export const useWindowSize = () => {
//   const [dimensions, setDimensions] = useState({
//     windowHeight: defaultHeight,
//     windowWidth: defaultWidth,
//   })

//   useEffect(() => {
//     const handler = () => setDimensions({
//       windowHeight: window.innerHeight,
//       windowWidth: window.innerWidth,
//     })

//     window.addEventListener(`resize`, handler)
//     return () => window.removeEventListener(`resize`, handler)
//   }, [])

//   return dimensions
// }

// ORGINAAL 

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height
//   };
// }



// export default function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return windowDimensions;
// }

// OTHER STUFF 

// let defaultHeight
// let defaultWidth

// if (typeof window !== `undefined`) {
//   defaultHeight = window.innerHeight
//   defaultWidth = window.innerWidth
// }

// const useWindowSize = () => {
//   const [dimensions, setDimensions] = useState({
//     windowHeight: defaultHeight,
//     windowWidth: defaultWidth,
//   })

//   useEffect(() => {
//     const handler = () => setDimensions({
//       windowHeight: window.innerHeight,
//       windowWidth: window.innerWidth,
//     })

//     window.addEventListener(`resize`, handler)
//     return () => window.removeEventListener(`resize`, handler)
//   }, [])

//   return dimensions
// }

// function debounce(fn, ms) {
//     let timer
//     return _ => {
//       clearTimeout(timer)
//       timer = setTimeout(_ => {
//         timer = null
//         fn.apply(this, arguments)
//       }, ms)
//     };
//   }
  
//   export const doSomething = function MyComponent(props) {
//     const [dimensions, setDimensions] = React.useState({ 
//       height: window.innerHeight,
//       width: window.innerWidth
//     })
//     React.useEffect(() => {
//       const debouncedHandleResize = debounce(function handleResize() {
//         setDimensions({
//           height: window.innerHeight,
//           width: window.innerWidth
//         })
//       }, 1000)
  
//       window.addEventListener('resize', debouncedHandleResize)
  
//       return _ => {
//         window.removeEventListener('resize', debouncedHandleResize)
      
//       }
//     })
//     // return <div>Rendered at {dimensions.width} x {dimensions.height}</div>
    
//   }